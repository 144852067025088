<template>
  <div class="container-group">
    <!-- Tipo de Pregunta -->
    <b-form-group
      v-if="isNaN(Question.id)"
      label="Tipo de pregunta"
      label-for="select-type"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="select-type"
        value-field="id"
        text-field="value"
        v-model="select_question_type"
        :options="question_types"
        aria-describedby="select-type-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="select-type-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
      <div
        class="text-danger ml-1"
        v-if="
          !enable_question_types.includes(select_question_type) &&
          select_question_type != null
        "
      >
        El Tipo de pregunta seleccionado no pertenece a la
        {{
          $getVisibleNames(
            "teaching.evaluationsituation",
            false,
            "Situación Evaluativa"
          )
        }}.
      </div>
    </b-form-group>
    <b-form-group
      v-if="select_question_type == 2"
      label="Subtipo de pregunta"
      label-for="select-type"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="select-type"
        value-field="id"
        text-field="value"
        v-model="$v.question.subtype.$model"
        :state="validateState('subtype')"
        :options="multipleChoiceQuestionSubTypes"
        aria-describedby="select-type-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="select-type-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
      <div
        class="text-danger ml-1"
        v-if="
          !enable_question_sub_types.includes(question.subtype) &&
          question.subtype != null
        "
      >
        El Subtipo de pregunta seleccionado no pertenece a la
        {{
          $getVisibleNames(
            "teaching.evaluationsituation",
            false,
            "Situación Evaluativa"
          )
        }}.
      </div>
    </b-form-group>
    <!-- Título -->
    <b-form-group
      :label="`${$getVisibleNames(
        'manual.test.enunciado',
        false,
        'Enunciado'
      )}`"
      label-for="input-name"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <div
        v-b-tooltip.v-secondary="
          `Editar ${$getVisibleNames(
            'manual.test.enunciado',
            false,
            'Enunciado'
          )}`
        "
        class="question-form-description rich-text-content mb-2"
        v-html="$v.question.title.$model"
        @click="$bvModal.show(`edit-question-title-modal-${question.id}`)"
      ></div>
      <b-modal
        :id="`edit-question-title-modal-${question.id}`"
        :title="`Editar ${$getVisibleNames(
          'manual.test.enunciado',
          false,
          'Enunciado'
        )}`"
        size="lg"
        hide-footer
        ignore-enforce-focus-selector="#sidebar-right"
      >
        <NewRichTextEditor
          :Object="question"
          :Text="question.title"
          @save="patchQuestionTitle"
          @close="$bvModal.hide(`edit-question-title-modal-${question.id}`)"
        ></NewRichTextEditor>
      </b-modal>
      <b-form-invalid-feedback id="input-name-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <!-- Descripción -->
    <b-form-group
      v-if="institution && institution.internal_use_id != 'duoc_uc'"
      label="Descripción"
      label-for="input-description"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <div
        v-b-tooltip.v-secondary="'Editar Descripción'"
        class="question-form-description rich-text-content mb-2"
        v-html="$v.question.description.$model"
        @click="$bvModal.show(`edit-question-description-modal-${question.id}`)"
      ></div>
      <b-modal
        :id="`edit-question-description-modal-${question.id}`"
        title="Editar Descripción de la Evaluación"
        size="lg"
        hide-footer
        ignore-enforce-focus-selector="#sidebar-right"
      >
        <NewRichTextEditor
          :Object="question"
          :Text="question.description"
          @save="patchQuestionDescription"
          @close="
            $bvModal.hide(`edit-question-description-modal-${question.id}`)
          "
        ></NewRichTextEditor>
      </b-modal>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es opcional.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-if="rubricCriterias.length > 0"
      :label="$getVisibleNames('manual.dimension', true, 'Dimensión')"
      label-for="linked_rubric_criteria"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="linked_rubric_criteria"
        v-model="$v.question.linked_rubric_criteria.$model"
        aria-describedby="linked_rubric_criteria-feedback"
        size="sm"
      >
        <b-form-select-option
          class="select-option-micro"
          v-for="ecm in rubricCriterias"
          :value="ecm.id"
          :key="ecm.id"
          :title="ecm.name"
        >
          {{ truncateText(ecm.order + ". " + ecm.full_sentence, 88) }}
        </b-form-select-option>
      </b-form-select>
      <b-form-invalid-feedback id="linked_rubric_criteria-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Orden"
      label-for="input-order"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-order"
        name="input-order"
        v-model="$v.question.order.$model"
        :state="validateState('order')"
        aria-describedby="input-order-live-feedback"
        size="sm"
        type="number"
      ></b-form-input>
      <b-form-invalid-feedback id="input-order-live-feedback"
        >Este campo debe ser mayor a 0.</b-form-invalid-feedback
      >
    </b-form-group>
    <!-- Puntaje -->
    <b-form-group
      label="Puntaje"
      label-for="input-max_score"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-max_score"
        v-model="$v.question.max_score.$model"
        :state="validateState('max_score')"
        type="number"
        aria-describedby="input-max_score-feedback"
        size="sm"
      ></b-form-input>
      <div
        v-if="
          institution &&
          institution.internal_use_id == 'duoc_uc' &&
          question.linked_rubric_criteria != null &&
          rubricsCriteria &&
          questionsWeighingRubricCriteria != rubricsCriteria.weighing
        "
        class="mb-2 text-danger"
      >
        <span v-if="questionsWeighingRubricCriteria > rubricsCriteria.weighing">
          Se excedió en el puntaje en: {{ suggestedScore * -1 }}, con respecto
          al IE seleccionado.
        </span>
        <span v-if="questionsWeighingRubricCriteria < rubricsCriteria.weighing">
          Puntaje disponible es de: {{ suggestedScore }}, correspondiente al IE
          seleccionado.
        </span>
      </div>
      <b-form-invalid-feedback id="input-max_score-feedback"
        >Este campo es obligatorio y debe ser mayor a
        0.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-if="
        evaluation &&
        (evaluation.matter_evaluation != null ||
          evaluation.egress_profile_matter_evaluation != null ||
          (evaluation.parent_evaluation != null &&
            evaluation_criteria_micro_ids.length > 0)) &&
        institution &&
        institution.internal_use_id != 'duoc_uc'
      "
      :label="
        $getVisibleNames(
          'teaching.evaluationcriteriamicro',
          false,
          'Criterio de Evaluación Micro'
        )
      "
      label-for="evaluation_criteria_micro"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <!-- Selector para Múltiples IL -->
      <b-form-select
        v-if="institution && institution.internal_use_id != 'duoc_uc'"
        id="evaluation_criterias_micro"
        v-model="$v.question.evaluation_criterias_micro.$model"
        aria-describedby="evaluation_criterias_micro-feedback"
        size="sm"
        multiple
        :select-size="
          evaluationCriteriaMicros.length < 2
            ? 2
            : evaluationCriteriaMicros.length > 10
            ? 10
            : evaluationCriteriaMicros.length
        "
      >
        <!-- <template #first>
          <b-form-select-option
            v-if="institution && institution.internal_use_id != 'duoc_uc'"
            :value="null"
          >
            --- Seleccione una de las opciones ---
          </b-form-select-option>
        </template> -->
        <b-form-select-option-group
          v-for="item in studyUnits"
          :key="item.id"
          :label="
            item.competence_unit_order != 999
              ? 'N°' +
                item.competence_unit_order +
                '.' +
                item.order +
                ': ' +
                item.name
              : 'N°' + item.order + ': ' + item.name
          "
        >
          <b-form-select-option
            class="select-option-micro"
            v-for="ecm in evaluationCriteriaMicros.filter(
              (x) => x.study_unit == item.id
            )"
            :value="ecm.id"
            :key="ecm.id"
            :title="ecm.name"
          >
            {{
              item.competence_unit_order != 999
                ? item.competence_unit_order +
                  "." +
                  item.order +
                  "." +
                  ecm.order +
                  " " +
                  ecm.full_sentence
                : item.order + "." + ecm.order + " " + ecm.full_sentence
            }}
          </b-form-select-option>
        </b-form-select-option-group>
      </b-form-select>

      <!-- Selector para sólo 1 IL -->
      <!-- <b-form-select
        id="evaluation_criteria_micro"
        v-model="$v.question.evaluation_criteria_micro.$model"
        aria-describedby="evaluation_criteria_micro-feedback"
        size="sm"
      >
        <template #first>
          <b-form-select-option
            v-if="institution && institution.internal_use_id != 'duoc_uc'"
            :value="null"
          >
            --- Seleccione una de las opciones ---
          </b-form-select-option>
        </template>
        <b-form-select-option-group
          v-for="item in studyUnits"
          :key="item.id"
          :label="'N°' + item.order + ': ' + item.name"
        >
          <b-form-select-option
            class="select-option-micro"
            v-for="ecm in evaluationCriteriaMicros.filter(
              (x) => x.study_unit == item.id
            )"
            :value="ecm.id"
            :key="ecm.id"
            :title="ecm.name"
          >
            {{ item.order }}.{{ ecm.order }} {{ ecm.full_sentence }}
          </b-form-select-option>
        </b-form-select-option-group>
      </b-form-select> -->
      <b-form-invalid-feedback id="evaluation_criterias_micro-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="mt-3 row">
      <div
        class="col"
        style="text-align: left"
        v-if="show_delete_button && !isNaN(question.id)"
      >
        <b-button size="sm" variant="danger" @click="askForDeleteQuestion">
          Eliminar
        </b-button>
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="saveQuestion">
          Guardar
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";
import { required, minValue } from "vuelidate/lib/validators";

export default {
  name: "NewQuestionForm",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
  },
  props: {
    test_id: {
      type: Number,
      required: true,
    },
    segment_id: {
      default: null,
    },
    order: {
      type: Number,
    },
    evaluation_criteria_micro_ids: {
      type: Array,
    },
    evaluation_situations: {
      type: Array,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    Question: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          title: "",
          description: "",
          max_score: 0,
          order: this.order,
          test: this.test_id,
          segment: this.segment_id,
          question_type: null,
          subtype: null,
          evaluation_criteria_micro: null,
          evaluation_criterias_micro: [],
          linked_rubric_criteria: null,
        };
      },
    },
  },
  data() {
    return {
      question: { ...this.Question },
      select_question_type: this.Question.question_type,
    };
  },
  validations: {
    question: {
      title: {},
      description: {},
      max_score: {
        required,
        minValue: minValue(0),
      },
      order: {
        minValue: minValue(0),
      },
      subtype: {
        isValid() {
          if (this.select_question_type == 2) {
            if (this.question.subtype != null) return true;
            else return false;
          }
          return true;
        },
      },
      evaluation_criteria_micro: {},
      evaluation_criterias_micro: {},
      linked_rubric_criteria: {},
    },
    validationGroup: ["question"],
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      rubrics: names.NEW_RUBRIC_INSTRUMENTS,
      rubric_criterias: names.NEW_RUBRIC_CRITERIAS,
      evaluations: names.NEW_EVALUATIONS,
      tests: names.NEW_TESTS,
      redaction_questions: names.NEW_REDACTION_QUESTIONS,
      multiple_choice_questions: names.NEW_MULTIPLE_CHOICE_QUESTIONS,
      multiple_choice_question_options:
        names.NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS,
      multiple_choice_question_sub_types:
        names.MULTIPLE_CHOICE_QUESTION_SUB_TYPES,
      filling_questions: names.NEW_FILLING_QUESTIONS,
      matching_questions: names.NEW_MATCHING_QUESTIONS,
      study_units: names.STUDY_UNITS,
      competence_units: names.COMPETENCE_UNITS,
      evaluation_criteria_micros: names.EVALUATION_CRITERIAS,
      rubrics_criterias: names.NEW_RUBRIC_CRITERIAS,
    }),
    test() {
      return this.tests.find((x) => x.id == this.test_id);
    },
    evaluationSituation() {
      if (!this.test) return null;
      return this.evaluation_situations.find(
        (x) => x.id == this.test.evaluation_situation
      );
    },
    enable_question_types() {
      if (!this.evaluationSituation) return [1, 2, 4];
      else {
        let question_enable = [];
        if (this.evaluationSituation.enable_redaction_questions)
          question_enable.push(1);
        if (
          this.evaluationSituation.enable_simple_selection_questions ||
          this.evaluationSituation.enable_multiple_selection_questions
        )
          question_enable.push(2);
        if (this.evaluationSituation.enable_matching_questions)
          question_enable.push(4);
        return question_enable;
      }
    },
    question_types() {
      let list = [
        { id: 1, value: "Desarrollo" },
        { id: 2, value: "Selección" },
        // { id: 3, value: "Auto Completar" },
        { id: 4, value: "Emparejamiento" },
      ];
      if (!this.evaluationSituation) return list;
      else {
        return list.filter(
          (x) =>
            this.enable_question_types.includes(x.id) ||
            this.select_question_type == x.id
        );
      }
    },
    enable_question_sub_types() {
      if (!this.evaluationSituation) return [1, 2];
      else {
        let question_sub_types_enable = [];
        if (this.evaluationSituation.enable_simple_selection_questions)
          question_sub_types_enable.push(1);
        if (this.evaluationSituation.enable_multiple_selection_questions)
          question_sub_types_enable.push(2);
        return question_sub_types_enable;
      }
    },
    multipleChoiceQuestionSubTypes() {
      if (!this.evaluationSituation)
        return this.multiple_choice_question_sub_types;
      else {
        return this.multiple_choice_question_sub_types.filter(
          (x) =>
            this.enable_question_sub_types.includes(x.id) ||
            x.id == this.question.subtype
        );
      }
    },
    evaluation() {
      if (!this.test) return null;
      return this.evaluations.find((x) => x.id == this.test.evaluation);
    },
    rubric() {
      return this.rubrics.find((x) => x.test == this.test_id);
    },
    rubricCriterias() {
      if (!this.rubric) return [];
      return this.rubric_criterias
        .filter((x) => x.rubric == this.rubric.id)
        .sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
    },
    evaluationCriteriaMicros() {
      return this.evaluation_criteria_micros.filter(
        (x) =>
          this.evaluation_criteria_micro_ids.includes(x.id) ||
          this.question.evaluation_criteria_micro == x.id ||
          this.question.evaluation_criterias_micro.includes(x.id)
      );
    },
    studyUnits() {
      const study_unit_ids = [
        ...new Set(this.evaluationCriteriaMicros.map((x) => x.study_unit)),
      ];
      return this.study_units
        .filter((x) => study_unit_ids.includes(x.id))
        .map((x) => {
          const competence_unit = this.competence_units.find(
            (c) => c.id == x.temp_competence_unit
          );
          return {
            ...x,
            competence_unit_order: competence_unit
              ? competence_unit.order
              : 999,
          };
        })
        .sort(function (a, b) {
          if (a.competence_unit_order > b.competence_unit_order) return 1;
          if (a.competence_unit_order < b.competence_unit_order) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    questions() {
      let list = [];
      list = list.concat(
        this.redactionQuestions.map((question) => ({
          ...question,
          question_type: 1,
        }))
      );
      list = list.concat(
        this.multipleChoiceQuestions.map((question) => ({
          ...question,
          question_type: 2,
        }))
      );
      // list = list.concat(
      //   this.fillingQuestions.map((question) => ({
      //     ...question,
      //     question_type: 3,
      //   }))
      // );
      list = list.concat(
        this.matchingQuestions.map((question) => ({
          ...question,
          question_type: 4,
        }))
      );
      return list.sort((a, b) => a.order - b.order);
    },
    redactionQuestions() {
      return this.redaction_questions.filter((x) => x.test == this.test_id);
    },
    multipleChoiceQuestions() {
      return this.multiple_choice_questions.filter(
        (x) => x.test == this.test_id
      );
    },
    multipleChoiceQuestionOptions() {
      if (this.Question.question_type != 2) return [];
      return this.multiple_choice_question_options.filter(
        (x) => x.multiple_choice_question == this.Question.id
      );
    },
    fillingQuestions() {
      return this.filling_questions.filter((x) => x.test == this.test_id);
    },
    matchingQuestions() {
      return this.matching_questions.filter((x) => x.test == this.test_id);
    },
    rubricsCriteria() {
      if (!this.question || this.question.linked_rubric_criteria == null)
        return null;
      return this.rubrics_criterias.find(
        (x) => x.id == this.question.linked_rubric_criteria
      );
    },
    questionsWeighing() {
      let maxScore = 0;
      if (this.test) maxScore = this.test.max_score;
      if (maxScore == 0) return 0;
      return parseFloat(
        ((parseFloat(this.question.max_score) / maxScore) * 100).toFixed(2)
      );
    },
    questionsWeighingRubricCriteria() {
      const questions_weighing = this.questionsWeighing;
      if (!this.rubricsCriteria || !this.test) return questions_weighing;
      const rubrics_criteria = this.rubricsCriteria;
      let questions_criteria = this.questions.filter(
        (x) =>
          x.linked_rubric_criteria == rubrics_criteria.id &&
          x.id != this.question.id
      );
      if (questions_criteria.length == 0) return questions_weighing;
      else {
        const questions_scores =
          questions_criteria.reduce(
            (acc, a) => acc + parseFloat(a.max_score),
            0
          ) + parseFloat(this.question.max_score);
        return parseFloat(
          ((questions_scores / this.test.max_score) * 100).toFixed(2)
        );
      }
    },
    suggestedScore() {
      if (
        this.question.linked_rubric_criteria != null &&
        this.rubricsCriteria
      ) {
        const correct_weighing = this.questionsWeighingRubricCriteria;
        const rubrics_criteria_weighing = this.rubricsCriteria.weighing;
        let max_score = 0;
        if (this.test) max_score = this.test.max_score;
        if (max_score == 0) return parseFloat(this.question.max_score);
        return parseFloat(
          (
            ((rubrics_criteria_weighing - correct_weighing) * max_score) /
            100
          ).toFixed(2)
        );
      } else return parseFloat(this.question.max_score);
    },
    maxScoreQuestions() {
      let score = 0;
      this.questions.forEach((element) => {
        if (
          element.id == this.question.id &&
          element.question_type == this.question.question_type
        ) {
          if (this.question.max_score != "" && this.question.max_score != null)
            score += parseFloat(this.question.max_score);
        } else score += parseFloat(element.max_score);
      });
      if (
        isNaN(this.question.id) &&
        this.question.max_score != "" &&
        this.question.max_score != null
      )
        score += parseFloat(this.question.max_score);
      return score;
    },
  },
  methods: {
    truncateText(text, maxLength) {
      const convert_text = text;
      if (convert_text.length > maxLength) {
        return convert_text.slice(0, maxLength) + "...";
      }
      return convert_text;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.question[name];
      return $dirty ? !$error : null;
    },
    patchQuestionTitle(object, text) {
      this.question.title = text;
    },
    patchQuestionDescription(object, text) {
      this.question.description = text;
    },
    saveQuestion() {
      this.$v.validationGroup.$touch();
      if (this.$v.validationGroup.$anyError) {
        return;
      }
      if (
        isNaN(this.question.id) &&
        this.test &&
        this.maxScoreQuestions > parseFloat(this.test.max_score)
      )
        this.updateMaxScoreTest();
      else {
        if (this.select_question_type == 1) {
          if (isNaN(this.question.id)) this.createRedactionQuestion();
          else this.updateRedactionQuestion();
        } else if (this.select_question_type == 2) {
          if (this.question.subtype == 1) this.question.how_many_selected = 1;
          else if (
            !this.question.how_many_selected ||
            this.question.how_many_selected < 2
          )
            this.question.how_many_selected = 2;
          if (isNaN(this.question.id)) this.createSelectedQuestion();
          else this.updateSelectedQuestion();
        } else if (this.select_question_type == 3) {
          if (isNaN(this.question.id)) this.createFillingQuestion();
          else this.updateFillingQuestion();
        } else if (this.select_question_type == 4) {
          if (isNaN(this.question.id)) this.createMatchingQuestion();
          else this.updateMatchingQuestion();
        }
      }
    },
    createRedactionQuestion() {
      this.$store
        .dispatch(names.POST_NEW_REDACTION_QUESTION, this.question)
        .then((response) => {
          this.question = response;
          toast("Se creó la Pregunta.");
          this.$emit("closeModal");
        });
    },
    updateRedactionQuestion() {
      this.$store
        .dispatch(names.UPDATE_NEW_REDACTION_QUESTION, this.question)
        .then((response) => {
          this.question = response;
          toast("Se editó la Pregunta.");
          this.$emit("closeModal");
        });
    },
    createSelectedQuestion() {
      this.$store
        .dispatch(names.POST_NEW_MULTIPLE_CHOICE_QUESTION, this.question)
        .then((response) => {
          this.question = response;
          toast("Se creó la Pregunta.");
          this.$emit("closeModal");
        });
    },
    updateSelectedQuestion() {
      if (this.Question.subtype == 2 && this.question.subtype == 1) {
        this.multipleChoiceQuestionOptions.forEach((x) => {
          if (x.score > 0)
            this.$store.dispatch(
              names.PATCH_NEW_MULTIPLE_CHOICE_QUESTION_OPTION,
              {
                new_multiple_choice_question_option_id: x.id,
                item: { score: 0 },
              }
            );
        });
      }
      this.$store
        .dispatch(names.UPDATE_NEW_MULTIPLE_CHOICE_QUESTION, this.question)
        .then((response) => {
          this.question = response;
          toast("Se editó la Pregunta.");
          this.$emit("closeModal");
        });
    },
    createFillingQuestion() {
      this.$store
        .dispatch(names.POST_NEW_FILLING_QUESTION, this.question)
        .then((response) => {
          this.question = response;
          toast("Se creó la Pregunta.");
          this.$emit("closeModal");
        });
    },
    updateFillingQuestion() {
      this.$store
        .dispatch(names.UPDATE_NEW_FILLING_QUESTION, this.question)
        .then((response) => {
          this.question = response;
          toast("Se editó la Pregunta.");
          this.$emit("closeModal");
        });
    },
    createMatchingQuestion() {
      this.$store
        .dispatch(names.POST_NEW_MATCHING_QUESTION, this.question)
        .then((response) => {
          this.question = response;
          toast("Se creó la Pregunta.");
          this.$emit("closeModal");
        });
    },
    updateMatchingQuestion() {
      this.$store
        .dispatch(names.UPDATE_NEW_MATCHING_QUESTION, this.question)
        .then((response) => {
          this.question = response;
          toast("Se editó la Pregunta.");
          this.$emit("closeModal");
        });
    },
    updateMaxScoreTest() {
      this.$swal({
        title: `<p>¡El puntaje de las preguntas no coincide con el puntaje máximo del <b>${this.$getVisibleNames(
          "evaluations2.test",
          false,
          "Instrumento"
        )}</b>!</p>`,
        text: `¿Desea que el puntaje del ${this.$getVisibleNames(
          "evaluations2.test",
          false,
          "Instrumento"
        )} se actualice automáticamente?`,
        confirmButtonText: "Sí",
        cancelButtonText: "No",
        type: "info",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.PATCH_NEW_TEST, {
              new_test_id: this.test_id,
              item: { max_score: this.maxScoreQuestions },
            })
            .then(() => {
              if (this.select_question_type == 1) {
                if (isNaN(this.question.id)) this.createRedactionQuestion();
                else this.updateRedactionQuestion();
              } else if (this.select_question_type == 2) {
                if (isNaN(this.question.id)) this.createSelectedQuestion();
                else this.updateSelectedQuestion();
              } else if (this.select_question_type == 3) {
                if (isNaN(this.question.id)) this.createFillingQuestion();
                else this.updateFillingQuestion();
              } else if (this.select_question_type == 4) {
                if (isNaN(this.question.id)) this.createMatchingQuestion();
                else this.updateMatchingQuestion();
              }
            });
        } else if (result.dismiss == "cancel") {
          if (this.select_question_type == 1) {
            if (isNaN(this.question.id)) this.createRedactionQuestion();
            else this.updateRedactionQuestion();
          } else if (this.select_question_type == 2) {
            if (isNaN(this.question.id)) this.createSelectedQuestion();
            else this.updateSelectedQuestion();
          } else if (this.select_question_type == 3) {
            if (isNaN(this.question.id)) this.createFillingQuestion();
            else this.updateFillingQuestion();
          }
        }
      });
    },
    askForDeleteQuestion() {
      this.$swal({
        title: `<p>¿Está seguro de que desea eliminar la pregunta?</p>`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          if (this.question.question_type == 1) {
            this.$store.dispatch(
              names.DELETE_NEW_REDACTION_QUESTION,
              this.question.id
            );
          } else if (this.question.question_type == 2) {
            this.$store.dispatch(
              names.DELETE_NEW_MULTIPLE_CHOICE_QUESTION,
              this.question.id
            );
          } else if (this.question.question_type == 3) {
            this.$store.dispatch(
              names.DELETE_NEW_FILLING_QUESTION,
              this.question.id
            );
          } else if (this.question.question_type == 4) {
            this.$store.dispatch(
              names.DELETE_NEW_MATCHING_QUESTION,
              this.question.id
            );
          }
          this.$emit("closeModal");
          toast("Se eliminó la pregunta ");
        }
      });
    },
  },
  mounted() {
    if (isNaN(this.question.id)) {
      if (this.question_types.length > 0)
        this.select_question_type = this.question_types[0].id;
      if (this.select_question_type == 2)
        this.question.subtype = this.multipleChoiceQuestionSubTypes[0].id;
    }
  },
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.question-form-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  max-height: 200px;
  overflow: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.question-form-description >>> p {
  margin-bottom: 0.7rem !important;
}
</style>

